import React from 'react'

// eslint-disable-next-line no-restricted-imports
import { useSubscription as useApolloSubscription } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import env from '@core/env'
import { useMainState } from '@core/main-state'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

// eslint-disable-next-line no-restricted-imports
import * as generatedService from './generated/services.gen'

type UseSubscription = typeof useApolloSubscription

export const useSubscription: UseSubscription = (query, options) => {
  const isIdle = useMainState((state) => state.isIdle)

  return useApolloSubscription(query, {
    ...options,
    skip: isIdle || options?.skip,
  })
}

export const queryClient = new QueryClient()

export const ApiProvider = ({ children }: { children: React.ReactNode }) => {
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
}

export const build401ReturnTo = () => {
  const qs = new URLSearchParams({
    returnTo: encodeURIComponent(`${window.location.pathname}${window.location.search}`),
    auth0ErrorParam: 'expired',
  })

  return `${window.document.location.origin}?${qs.toString()}`
}

const service = generatedService

type DefaultService = typeof generatedService

const BFFClientContext = React.createContext(service)

export const BffClientProvider = (props: {
  children: React.ReactNode
  client?: DefaultService
}) => {
  const { getAccessTokenSilently, logout } = useAuth0()

  const clientWithAuth = React.useMemo(() => {
    const copy = props.client || service

    service.client.setConfig({ baseUrl: env.FTG_BFF_URL })

    service.client.interceptors.request.use(async (request) => {
      const token = await getAccessTokenSilently()
      request.headers.set('Authorization', `Bearer ${token}`)
      return request
    })

    service.client.interceptors.response.use(async (response, request) => {
      if (response.status === 401) {
        logout({ logoutParams: { returnTo: build401ReturnTo() } })
      } else if (response.status > 401) {
        // @TODO: verify the necessity of capturing returned errors (maybe only 500s?)
        console.warn(
          'ui: request error',
          request.method,
          request.url,
          response.status,
          await response.text(),
        )
      }

      return response
    })

    return copy
  }, [props.client, getAccessTokenSilently, logout])

  return (
    <BFFClientContext.Provider value={clientWithAuth}>{props.children}</BFFClientContext.Provider>
  )
}

export const useApi = () => {
  return React.useContext(BFFClientContext)
}
