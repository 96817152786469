import React from 'react'

import { useApi } from '@core/api'
import { getClient } from '@core/api/api-state'
import { getUserRole, useBffEnabled } from '@core/main-state'
import { addSnack, useSnackState } from '@core/snack/snack-state'
import { ItemStatus, ItemStatusMap } from '@core/types'
import { useItemWatchStatus } from '@core/watch-item-status/watch-item-status-state'
import {
  GENERATE_POSSIBLE_KEYS,
  GENERATE_RATIONALES,
  ITEM_BIAS,
} from '@pages/author/author-queries'
import { useMutation } from '@tanstack/react-query'

import * as queries from './content-version-queries'
import { useContentVersionState, type Slice } from './content-version-state' // only types allowed!
import type { ItemActions } from './content-version-types'

export const notifyPossibleKeys = (options: { possible?: string }[]) => {
  const hasPossibleKeys = options?.find((answer) => answer?.possible?.toUpperCase() === 'KEY')

  if (hasPossibleKeys) {
    addSnack({
      message:
        // eslint-disable-next-line max-len
        'Edits to the generated stimulus, stem or options may invalidate suggested possible keys',
      severity: 'info',
      checkboxKey: 'hidePossibleKeysInfo',
    })
  } else {
    addSnack({
      message: 'A highly probable key was not found. Edit or regenerate options and try again.',
      severity: 'info',
      checkboxKey: 'hidePossibleKeysNotFoundInfo',
    })
  }
}

type UpdateItemStatusArgs = {
  rootId: string
  itemId: string
  updatedAt?: string
  status: ItemStatus
  override?: boolean
}

const updateItemStatus = ({
  rootId,
  itemId,
  updatedAt = new Date().toISOString(),
  status,
  override = false,
}: UpdateItemStatusArgs) => {
  const { onUpdate, overrideItem } = useItemWatchStatus.getState()

  if (override) {
    return overrideItem(rootId, itemId, { status, updatedAt })
  }

  return onUpdate(rootId, [{ id: itemId, status, updatedAt }])
}

type CreateItemActions = (
  rootId: string,
  get: Slice['getState'],
  set: Slice['setState'],
) => ItemActions

// Common actions that can be generic enough to be used in multiple places
export const createItemActions: CreateItemActions = (rootId, get) => ({
  async regenerateItem(itemKey, type) {
    const oldStatus = get().instances[rootId].items[itemKey].status
    const itemId = itemKey === 'root' ? rootId : itemKey

    updateItemStatus({ rootId, itemId, status: ItemStatusMap.IN_PROGRESS })

    try {
      const client = getClient()
      const { role } = getUserRole()

      await get().instances[rootId].updater.flush()

      await client.mutate({
        mutation: queries.REGENERATE_ITEM,
        variables: {
          itemId,
          clearStem: type === 'stem',
          clearOptions: true,
        },
        context: { role },
      })
    } catch (error) {
      console.error('error while regenerating item', { error, rootId, itemId, type })

      updateItemStatus({ rootId, itemId, status: oldStatus, override: true })
    }
  },

  async cloneChild(itemId) {
    const client = getClient()
    const { role } = getUserRole()

    await get().instances[rootId].updater.flush()

    const { data } = await client.mutate({
      mutation: queries.CLONE_ITEM,
      variables: { itemId },
      context: { role },
    })

    if (!data?.clone?.id) {
      throw new Error('No clone id returned')
    }

    const newItemId = data.clone.id

    return newItemId
  },

  async archiveItem(itemId) {
    const client = getClient()
    const { role } = getUserRole()

    try {
      const { data } = await client.mutate({
        mutation: queries.ARCHIVE_ITEM,
        variables: { itemId },
        context: { role },
      })

      if (!data?.result?.id) {
        console.warn('No archive result id returned', { itemId })

        addSnack({
          message: 'Error while deleting the item',
          severity: 'error',
        })

        return { success: false }
      }

      addSnack({
        message: 'Item deleted',
        severity: 'success',
      })

      return { success: true }
    } catch (error) {
      addSnack({
        message: 'Error while deleting the item',
        severity: 'error',
      })

      throw error
    }
  },

  async discardItemsUnsavedContent() {
    const { instances, setInstance } = get()
    let instance = instances[rootId]

    const copy = { ...instance.items }
    const itemChanges = { ...instance.itemChanges }
    const itemKeys = Object.keys(copy).sort()

    for (const itemKey of itemKeys) {
      const item = copy[itemKey]

      if (!item.savedContent) continue

      copy[itemKey] = {
        ...item,
        currentContent: item.savedContent,
      }
      itemChanges[itemKey] = Object.keys(item.savedContent).reduce((acc, key) => {
        return {
          ...acc,
          [key]: true,
        }
      }, {})
    }

    setInstance(rootId, (prev) => {
      prev.items = copy
      prev.itemChanges = itemChanges
      prev.unsavedChanges = false
      prev.unsavedChangesFromLastTime = false
      prev.rootKey += 1
    })

    instance = get().instances[rootId]

    if (instance) {
      instance.validator(instance)
      instance.updater(instance)
      instance.updater.flush()
    }
  },

  async generateRationales({
    itemId: itemKey,
    keysOnly,
    option,
    generateRationaleFor,
    itemKeyCheck,
  }) {
    const oldStatus = get().instances[rootId].items[itemKey].status
    const itemId = itemKey === 'root' ? rootId : itemKey

    updateItemStatus({
      rootId,
      itemId,
      status: itemKeyCheck ? ItemStatusMap.PRETEST_KEY : ItemStatusMap.RATIONALES,
    })

    const label = itemKeyCheck ? 'key check results' : 'rationales'
    try {
      const client = getClient()
      const { role } = getUserRole()

      await get().instances[rootId].updater.flush()

      addSnack({
        message:
          // eslint-disable-next-line max-len
          `This may take a little time to generate your ${label}! You can continue authoring your other items in the meantime. Check back soon.`,
        severity: 'info',
        checkboxKey: 'hideRationalesInfo',
      })

      await client.mutate({
        mutation: GENERATE_RATIONALES,
        variables: {
          itemId: itemId === 'root' ? rootId : itemId,
          keysOnly,
          option,
          generateRationaleFor,
          itemKeyCheck,
        },
        context: { role },
      })

      return { success: true }
    } catch (error) {
      useSnackState.getState().reset()
      addSnack({
        message: `Item ${label} generation failed. Please try again`,
        severity: 'error',
      })

      updateItemStatus({ rootId, itemId, status: oldStatus, override: true })

      return { success: false }
    }
  },

  async runItemBiasCheck(itemId) {
    const oldStatus = get().instances[rootId].items.root.status

    updateItemStatus({ rootId, itemId, status: ItemStatusMap.PRETEST_BIAS })

    try {
      const client = getClient()
      const { role } = getUserRole()

      await get().instances[rootId].updater.flush()

      await client.mutate({
        mutation: ITEM_BIAS,
        variables: {
          itemId,
        },
        context: { role },
      })

      return { success: true }
    } catch (error) {
      useSnackState.getState().reset()
      addSnack({
        message: 'Item bias check failed. Please try again',
        severity: 'error',
      })

      updateItemStatus({ rootId, itemId, status: oldStatus, override: true })

      return { success: false }
    }
  },
})

export const useItemActions = (rootId: string) => {
  const api = useApi()
  const bffEnabled = useBffEnabled()

  const generatePossibleKeys = useMutation({
    // @TODO: centralize query/mutation keys in a single place
    mutationKey: ['possibleKeys', rootId],
    mutationFn: async (itemKey: string): Promise<{ success: boolean }> => {
      const instance = useContentVersionState.getState().instances[rootId]
      const oldStatus = instance.items[itemKey].status
      const itemId = itemKey === 'root' ? rootId : itemKey

      updateItemStatus({ rootId, itemId, status: ItemStatusMap.POSSIBLE_KEYS })

      try {
        await instance.updater.flush()

        if (bffEnabled.possibleKeys) {
          const response = await api.possibleKeys({
            path: { item_id: itemId },
          })

          if (!response.data) {
            console.warn('Failed possible keys')
            return { success: false }
          }

          return { success: Boolean(response.data.ok) }
        }

        const client = getClient()
        const { role } = getUserRole()

        await client.mutate({
          mutation: GENERATE_POSSIBLE_KEYS,
          variables: { itemId },
          context: { role },
        })

        return { success: true }
      } catch (error) {
        // @TODO: with apollo, we have single place to capture all errors and send to Sentry
        // what about here?
        addSnack({
          message: 'Possible key generation failed. Please try again',
          severity: 'error',
        })
        updateItemStatus({ rootId, itemId, status: oldStatus, override: true })
        return { success: false }
      }
    },
  })

  const saveItem = useMutation({
    mutationKey: ['saveItem', rootId],
    mutationFn: async (projectId: string): Promise<{ success: boolean }> => {
      const instance = useContentVersionState.getState().instances[rootId]

      await instance.updater.flush()

      try {
        let isSuccess = false
        if (bffEnabled.saveItem) {
          const response = await api.saveItem({
            path: { item_id: rootId },
            body: { project_id: projectId },
          })
          isSuccess = !!response.data?.ok
        } else {
          const client = getClient()
          const { role } = getUserRole()

          const { data } = await client.mutate({
            mutation: queries.SAVE_ITEM,
            variables: { id: rootId, projectId },
            context: { role },
          })
          isSuccess = data?.deliverContent?.ok ?? false
        }

        if (!isSuccess) {
          addSnack({
            message: 'An error occurred during saving.',
            severity: 'error',
          })
          return { success: false }
        }

        return { success: true }
      } catch (error) {
        addSnack({
          message: 'An error occurred during saving.',
          severity: 'error',
        })
        return { success: false }
      }
    },
  })

  return React.useMemo(() => {
    return {
      generatePossibleKeys,
      saveItem,
      // @TODO: port other item common actions
    }
  }, [generatePossibleKeys, saveItem])
}
