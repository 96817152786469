import { gql, TypedDocumentNode } from '@apollo/client'

import type { RawItemSet } from './author-cloze-types'

export const GET_ITEM_SET: TypedDocumentNode<{ item: RawItemSet }, { id: string }> = gql`
  query fetchItemSet2($id: uuid!) {
    item: item_by_pk(id: $id) {
      id
      status
      type: content_type
      project {
        id
        name
        access {
          write
          download
        }
      }
      aiModel: ai_model {
        public_mapper
        flavors
        comments
        name
        qualityMetrics: quality_metrics
        type
        externalMetadataSettings: external_metadata_settings
      }
      job {
        id
        ownerId: owner_id
        selectedSubmodels: selected_submodels
      }
      currentContent: content_version(args: { content_type: "current" }) {
        submodels
        external_metadata
        content
        qualityMetrics: quality_metrics
        comments
      }
      savedContent: content_version(args: { content_type: "saved" }) {
        content
        submodels
        external_metadata
      }
      originalContent: content_version(args: { content_type: "original" }) {
        submodels
      }
      children {
        id
        type: content_type
        currentContent: content_version(args: { content_type: "current" }) {
          content
          submodels
        }
        savedContent: content_version(args: { content_type: "saved" }) {
          content
          submodels
        }
        originalContent: content_version(args: { content_type: "original" }) {
          submodels
        }
        status
        step
        contentFilter: content_filter
        aiModelId: ai_model_id
      }
    }
  }
`

export const GET_ITEM_CONTENT = gql`
  query fetchItemContent($id: uuid!) {
    item: item_by_pk(id: $id) {
      id
      currentContent: content_version(args: { content_type: "current" }) {
        content
        submodels
        qualityMetrics: quality_metrics
      }
      savedContent: content_version(args: { content_type: "saved" }) {
        content
      }
      originalContent: content_version(args: { content_type: "current" }) {
        submodels
      }
      status
      step
      type: content_type
      contentFilter: content_filter
    }
  }
`

export type ItemUpdatesChildData = {
  id: string
  status: string
  step: string
}

export type ItemUpdatesData = {
  id: string
  status: string
  step: string
  currentContent: {
    qualityMetrics: { value: number }[]
  }[]
  children: ItemUpdatesChildData[]
}

export const GET_ITEM_UPDATES: TypedDocumentNode<{ item: ItemUpdatesData }, { id: string }> = gql`
  subscription fetchItemSetStatus($id: uuid!) {
    item: item_by_pk(id: $id) {
      id
      status
      step
      currentContent: content_version(args: { content_type: "current" }) {
        qualityMetrics: quality_metrics
      }
      children {
        id
        status
        step
      }
    }
  }
`

export const SAVE_ITEM = gql`
  mutation saveItem($id: uuid!, $projectId: uuid) {
    deliverContent: deliver_content(item_id: $id, project_id: $projectId) {
      ok
    }
  }
`

export const REGENERATE_ITEM = gql`
  mutation regenerateItem($itemId: uuid!, $clearStem: Boolean, $clearOptions: Boolean) {
    generate_item(item_id: $itemId, clear_stem: $clearStem, clear_options: $clearOptions) {
      items_ids
      job_id
    }
  }
`

export const REGENERATE_ROOT_QUESTION = gql`
  mutation regenerateRootQuestion($itemId: uuid!, $aiModelId: uuid!) {
    generate_item(item_id: $itemId, ai_model_id: $aiModelId, clear_root_stimulus: true) {
      items_ids
    }
  }
`

export const GENERATE_ITEM = gql`
  mutation generateItem(
    $itemId: uuid
    $empty: Boolean = false
    $aiModelId: uuid
    $rootId: uuid
    $flavors: [String]
  ) {
    generate: generate_item(
      item_id: $itemId
      empty: $empty
      ai_model_id: $aiModelId
      root_id: $rootId
      submodels: $flavors
    ) {
      items_ids
    }
  }
`

export const UPDATE_ITEM_CONTENT = gql`
  mutation updateItemContent($content: jsonb!, $id: uuid!, $submodels: jsonb!) {
    upsert_content_version(
      content_version: {
        content: $content
        item_id: $id
        type: "USER_INPUT"
        submodels: $submodels
      }
    ) {
      ok
    }
  }
`

export const UPDATE_ITEM_ROOT = gql`
  mutation updateRootContent(
    $content: jsonb!
    $id: uuid!
    $qualityMetrics: jsonb!
    $submodels: jsonb!
  ) {
    upsert_content_version(
      content_version: {
        content: $content
        item_id: $id
        type: "USER_INPUT"
        quality_metrics: $qualityMetrics
        submodels: $submodels
      }
    ) {
      ok
    }
  }
`
