import type { BffFeatureFlags } from '@core/api/bff'

export const exportOptions = ['qti', 'text', 'comptia', 'surpass', 'csv']
export const exportMathOptions = ['LaTeX', 'MathML'] as const
export const mathKeyboards = [
  'all',
  'finance',
  // 'science',
]

export const exportMathDelimiterOptions = ['\\(  \\)', '\\[  \\]', '$$  $$'] as const
export type ExportMathOptions = (typeof exportMathOptions)[number]
export type ExportMathDelimiter = (typeof exportMathDelimiterOptions)[number]
export type MathKeyboards = { [key: string]: typeof mathKeyboards }

export type features =
  | 'read'
  | 'trust_vote'
  | 'possible_keys'
  | 'rationales'
  | 'pretest_key'
  | 'pretest_content'
  | 'pretest_style'
  | 'pretest_bias'

export const pretestOptions: { label: string; value: features; key: string }[] = [
  { label: 'Key', value: 'pretest_key', key: 'pretestKey' },
  { label: 'Content', value: 'pretest_content', key: 'pretestContent' },
  { label: 'Style', value: 'pretest_style', key: 'pretestStyle' },
  { label: 'Bias', value: 'pretest_bias', key: 'pretestBias' },
]

type PreTestFeatures = {
  pretest_bias?: boolean
  pretest_content?: boolean
  pretest_key?: boolean
  pretest_style?: boolean
}

export type CustomerFeatures = {
  bff_enabled?: BffFeatureFlags
  export_file?: typeof exportOptions
  export_math?: ExportMathOptions | string
  learn_v2?: boolean
  possible_keys?: boolean
  rationales?: boolean
  math_keyboards?: MathKeyboards | string[]
  export_math_delimiter?: ExportMathDelimiter | string
  probuilder_integration?: boolean
}

export type FormValues = {
  hasOptionRationale: boolean
  features: CustomerFeatures & PreTestFeatures
}

export type Customer = {
  id: string
  name: string
  updatedAt: string
  createdAt: string
  ssoConnectionName: string | null
} & FormValues

export type AiModel = {
  id: string
  name: string
  createdAt: string
  type: string
  isInternal: boolean
  internalName: string
}

export type Role = 'User' | 'Admin'

export interface User {
  id: string
  firstName: string
  lastName: string
  email: string
  name: string
  role: Role
  canResetCustomerData: boolean
  canDeleteProjects: boolean
  picture: string
  internalAdmin: boolean
  isInternal: boolean
}
