import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

import type { Customer, User } from '@containers/main/main-types'
import { AUTHOR_ROUTE, DELIVER_DRAFT, DELIVER_ROUTE, USERS_ROUTE } from '@core/constants/routes'
import env from '@core/env'
import { RootState, useAppSelector } from '@core/store'
import locationUtils from '@core/utils/location'
import { createSelector } from '@reduxjs/toolkit'

export const claims = 'https://hasura.io/jwt/claims'

export type ArrElement<ArrType> = ArrType extends readonly (infer ElementType)[]
  ? ElementType
  : never

interface Access {
  write: boolean
  download?: boolean
}

interface PartialProject {
  access: Access[]
}

interface PartialItem {
  job?: {
    ownerId: string
  }
  project?: PartialProject | null
}

export const selectUserRole = createSelector(
  (state: RootState) => state.main.user,
  (user) => {
    if (!user) throw new Error('Missing user on redux main state.')
    const isAdmin = user.role === 'Admin'
    if (user.internalAdmin) return { user, role: 'internal_admin', isAdmin }

    return { user, role: isAdmin ? 'customer_admin' : 'AUTHOR', isAdmin }
  },
)

type UserAccess = ReturnType<typeof selectUserRole>

interface GetAccessParams {
  userAccess: UserAccess
  item: PartialItem
}

export function getAccess({ userAccess, item: { job, project } }: GetAccessParams): Access {
  if (userAccess.isAdmin) return { write: true, download: true }
  // if the user can select the project, but the access is not configured,
  // this means that he is the owner
  const isOwner = job?.ownerId === userAccess.user.id || project?.access.length === 0
  const access = project?.access[0]

  if (access) return access

  return isOwner ? { write: true, download: true } : { write: false, download: false }
}

// @DEPRECATE - use ui/src/core/main-state.ts intead
export function useUserRole() {
  const userAccess = useAppSelector(selectUserRole)

  return useMemo(
    () => ({
      ...userAccess,
      getAccess: (item: PartialItem) => getAccess({ userAccess, item }),
    }),
    [userAccess],
  )
}

export function useQuery() {
  const location = useLocation()
  return {
    query: new URLSearchParams(location.search),
    location,
  }
}

export function onChangePermissions() {
  const allowed = [DELIVER_ROUTE, DELIVER_DRAFT, USERS_ROUTE, AUTHOR_ROUTE]
  const currentRoute = locationUtils.getCurrentPath()

  if (!allowed.includes(currentRoute)) {
    locationUtils.navigateTo(AUTHOR_ROUTE)
  } else {
    locationUtils.reload()
  }
}

export function pendoSetup(user: User, customer: Customer | null) {
  /* below is a modified version of the Pendo installation script.
    The script was modified to adhere to our linting rules and be more proper TypeScript.
    */

  const p = window
  const e = document
  const n = 'script'
  const d = 'pendo'
  let w: number
  let x: number

  let o2 = { _q: PropTypes.any }
  const p2 = p
  p2[d] = p2[d] || {}
  o2 = p2[d]
  o2._q = o2._q || []

  const v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track']
  for (w = 0, x = v.length; w < x; w += 1) {
    const m = v[w]
    o2[m] =
      o2[m] ||
      function custom() {
        // eslint-disable-next-line prefer-rest-params
        o2._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)))
      }
  }

  const y = e.createElement(n)
  y.setAttribute('async', (!0).toString())
  y.setAttribute('src', `https://cdn.pendo.io/agent/static/${env.PENDO_API_KEY}/pendo.js`)

  const z = e.getElementsByTagName(n)[0]
  if (z.parentNode) {
    z.parentNode.insertBefore(y, z)
  }

  window.pendo.initialize({
    visitor: {
      id: user?.email,
      uuid: user?.id,
      full_name: user?.name,
      role: user?.internalAdmin ? 'Internal Admin' : user?.role,
    },

    account: {
      id: customer?.name,
      customer_id: customer?.id,
    },
  })
}
