import { TabValues } from '@core/constants/generate-tab-values'
import { hasLessThanChars, validateInputCheck } from '@core/utils/text'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { authorClear } from './author-actions'
import * as thunk from './author-thunk'
import { Option, State } from './author-types'

export const MIN_WORDS_LENGTH = 3

export function getInitialState(): State {
  return {
    selectedModel: null,
    itemInputs: {},
    paginatedItemsIds: [],
    customPassageEnabled: false,
    customPassage: '',
    selectedFlavors: [],
    isContentEditOn: false,
    loading: {
      generate: false,
      savingContent: false,
      deliver: false,
      discard: false,
      fetchingItem: false,
    },
    batchFailed: false,
    flexibleInputsTab: 0,
    selectedTab: TabValues.TAB_GENERATE,
    selectedCreativity: null,
    addCustomInput: false,
    editorSettings: { keyboards: [] },
  }
}
export { authorClear }

const minLengthError = {
  name: 'minLength',
  params: [3],
  errorMessage: 'Less than 3 characters',
}

export const slice = createSlice({
  name: 'author',
  initialState: getInitialState(),
  reducers: {
    set(state: State, action: PayloadAction<Partial<State>>) {
      Object.assign(state, action.payload)
    },

    stopLoading(state: State) {
      state.loading = getInitialState().loading
    },
    setListInput(
      state: State,
      action: PayloadAction<{ name: string; value: string; line?: number; type: 'list' | 'item' }>,
    ) {
      if (state.selectedModel?.inputSettings) {
        const { type, name, line = '', value } = action.payload
        const inputSetting = state.selectedModel.inputSettings.find((x) => x.name === name)
        const { itemInputs } = state

        if (!inputSetting) return

        const elements = ((itemInputs && itemInputs[name]) as string[]) || []

        if (type === 'list') {
          elements.push(value)

          const validation = validateInputCheck(inputSetting.checks, elements)
          const lessThanThree = hasLessThanChars(elements, MIN_WORDS_LENGTH)
          inputSetting.errorMessage = lessThanThree ? minLengthError : validation
        }
        if (type === 'item' && elements) {
          elements[line] = value
          const validation = validateInputCheck(inputSetting.checks, elements)
          const lessThanThree = hasLessThanChars(elements, MIN_WORDS_LENGTH)
          inputSetting.errorMessage = lessThanThree ? minLengthError : validation
        }

        state.itemInputs = { ...itemInputs, [name]: elements }
      }
    },
    deleteInput(state: State, action: PayloadAction<{ name: string; line: number }>) {
      if (state.selectedModel?.inputSettings) {
        const { name, line } = action.payload
        const inputSetting = state.selectedModel.inputSettings.find((x) => x.name === name)
        const { itemInputs } = state

        if (!inputSetting) return

        const elements = (itemInputs && (itemInputs[name] as string[])) || []
        elements.splice(line, 1)

        const validation = validateInputCheck(inputSetting.checks, elements)
        const lessThanThree = hasLessThanChars(elements, MIN_WORDS_LENGTH)
        inputSetting.errorMessage = lessThanThree ? minLengthError : validation

        state.itemInputs = { ...itemInputs, [name]: elements }
      }
    },
    setInputText(state: State, action: PayloadAction<{ name: string; value: string }>) {
      if (state.selectedModel?.inputSettings) {
        const { name, value } = action.payload
        const inputSetting = state.selectedModel.inputSettings.find((x) => x.name === name)
        const { itemInputs } = state

        if (!inputSetting) return

        state.itemInputs = { ...itemInputs, [name]: value }
        const validation = validateInputCheck(inputSetting.checks, value)

        if (!inputSetting.required && !value) inputSetting.errorMessage = null
        else inputSetting.errorMessage = validation
      }
    },
    setOptions(state: State, action: PayloadAction<{ name: string; value: string | string[] }>) {
      if (state.selectedModel?.inputSettings) {
        const { name, value } = action.payload
        const input = state.selectedModel.inputSettings.find((x) => x.name === name)
        const { itemInputs } = state

        if (!input || !input?.options) return
        const selectedOption = input.options.find((x) => x.text === value) as Option

        state.itemInputs = { ...itemInputs, [name]: selectedOption }
        const validation = validateInputCheck(input.checks, value)

        if (!input.required && !input.value) input.errorMessage = null
        else input.errorMessage = validation
      }
    },

    setLoading(state: State, action: PayloadAction<Partial<State['loading']>>) {
      Object.assign(state.loading, action.payload)
    },
    reset(state) {
      Object.assign(state, getInitialState())
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunk.generateItem.pending, (state) => {
        state.loading.generate = true
      })
      .addCase(thunk.generateItem.rejected, (state) => {
        state.loading.generate = false
      })

    builder.addCase(authorClear, (state: State) => {
      Object.assign(state, getInitialState())
    })
  },
})

export default slice

export const { actions } = slice
